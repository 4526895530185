body {
    color: #5f5f5fb2;
    background-color: rgb(227 233 233);
    /* zoom: 90% !important;  */
}

.container-line{
    min-height: 87vh;
    background-color: rgba(238,244,247,255);
}


textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

/* Validation Message */
.srv-validation-message{
    display: column;
    font-size: 12px;
    color: #ff0000af;
    padding: 0px;
}

.mini-fund-counter{
    display: none;
}

.error-palate{
    display: flex;
    color: #ff0000af;
    font-weight: bold;
    font-size: 12px;
}
/* .form-control:focus, .form-control:active {
    box-shadow: none;
} */

.body-login{
    color: #5f5f5fb2;
    background-color:#fff;
    background-image: url('../assets/images/background-login.jpg');
    background-size:     cover;                 
    background-repeat:   no-repeat;
    background-attachment: fixed;
    overflow-y: hidden;
}

.login-front-img{
    margin-top: 20px;
    height: 420px;
    width: 450px;
}

.form-control{
    font-size: 14px;
}

.body-register{
    color: #5f5f5fb2;
    background-color:#fff;
    background-image: url('../assets/images/body2.jpg');
    background-size:     cover;                 
    background-repeat:   no-repeat;
    background-attachment: fixed;
    overflow-y: hidden;
}

/* LOGIN */
.login-card{
    position: relative;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 8px;
    min-height: 500px;
    /* z-index: -1; */
    -webkit-box-shadow: 0 15px 10px rgba(119, 119, 119, 0.457);
    -moz-box-shadow: 0 15px 10px rgba(119, 119, 119, 0.457);
    box-shadow: 0 15px 10px rgba(119, 119, 119, 0.457);
    
}

.container-line .logo-style{
    height: 45px;
}

.card-text{
    font-size: 13px;
    font-weight: 500;
}

.card-button{
    position:absolute;
    bottom: 20px;
}

ie {
    position: absolute;
    top: 10%;
    bottom: 5%;
    right: 2%;
    z-index: 1000;
  }
  ie:hover {
    color: #00fcb6;
    cursor: pointer;
  }

  is {
    position: absolute;
    top: 20%;
    bottom: 5%;
    right: 5%;
    z-index: 1000;
  }
  is:hover {
    color: #00fcb6;
    cursor: pointer;
  }

  ir {
    position: absolute;
    top: 52%;
    bottom: 5%;
    right: 5%;
    z-index: 1000;
  }
  ir:hover {
    color: #00fcb6;
    cursor: pointer;
  }

.line-separator{
    font-size: 13px;
    font-weight: 500;
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #5f5f5f45; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
}

.line-separator span{
    padding:0 10px; 
    background:#fff; 
}

.underline{
    text-decoration: underline;
    cursor: pointer;
}

.forget-password{
    font-size: 12px;
}

.forget-password:hover, .forget-password:focus {
    text-decoration: underline;
    cursor: pointer;
}

.login-button{
    background-color: #2a6877;
    font-size: 12px;
    border-radius: 5px;
    border-color: #2a6877;
    width: 80px;
}

.login-button:active{
    background-color: #183136; 
 }
 
 .login-button:focus{
     background-color: #183136; 
  }
 
 .login-button:hover{
     background-color: #183136; 
}

.login-button-dg{
    background-color: #d9534f;
    font-size: 12px;
    border-radius: 5px;
    border-color: #d9534f;
    width: 80px;
}

.login-button-dg:active{
    background-color: #d9534f; 
 }
 
.login-button-dg:focus{
    background-color: #d9534f; 
}
 
.login-button-dg:hover{
    background-color: #d9534f; 
}

/* WRAP */
.main-wrap{
    display: flex;
    /* min-height: 100vh; */
    flex-direction: column;
    /* justify-content: space-between; */
    padding-top: 10px;
    overflow: hidden;
    overflow-y: hidden;
    /* height: 100%; */
    /* padding-bottom: 10px; */
    /* padding-right: 30px;
    padding-left: 30px; */
}

.form-label{
    font-size: 12px;
    font-weight: bold;
}

/** OTP Card **/
.otp-card{
    position: relative;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 8px;
    min-height: 400px;
    /* z-index: -1; */
    -webkit-box-shadow: 0 15px 10px rgba(119, 119, 119, 0.457);
    -moz-box-shadow: 0 15px 10px rgba(119, 119, 119, 0.457);
    box-shadow: 0 15px 10px rgba(119, 119, 119, 0.457);
    
}

/* LOGO */

.logo{
    /* right: 5px; */
    /* padding-left: 30px;
    padding-right: 30px; */
}

/* USER INFORMATION */
.user-downer{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.user-info{
    text-align: right;
    margin-right: 10px;
    align-items: center;
}

/* Vertical Line */
.vertical-line{
    width: 1px; /* Line width */
    background-color: black; /* Line color */
    height: 100%; /* Override in-line if you want specific height. */
    
}

.line-center{
    margin-left:50%;
}

.center {
    margin: auto;
}

/* TEXT CSS */
.text-right{
    text-align: right;
}

.text-left{
    text-align: left;
}

.dark-blue{
    color: #4f8aaf;
}

.user-profile{
    border-radius: 50%;
    width: 55px;
    height: 55px;
    cursor: pointer;
}

.user-name{
    font-size: 15px;
    margin:0px;
    text-overflow: ellipsis;
    overflow:hidden; 
    white-space: nowrap;
    text-align: right;
    max-width: 200px;
}

.account-number{
    font-size: 12px;
    font-weight: bold;
    margin:0px;
}

.just-center{
    display: flex;
    justify-content: center;
}

.account-number:focus, .account-number:hover{
    text-decoration: underline;
    cursor: pointer;
}

.mobile-signout{
    display: none !important;
}

/* NEWS */
.news{
    display: inline;
    flex-direction:row;
    justify-content: space-between;
    padding: 10px;
    font-weight: bold;
    white-space: nowrap;
    height: 50px;
    width: 72%;
}

.mini-fund-counter{
    font-weight: bold;
    white-space: nowrap;
    height: 50px;
    width: 72%;
}

.news-container{
    display: flex;
    flex-direction:row;
    /* padding-left: 35px; */
    width: 80%;
}

.news-topic{
    margin: auto;
    color: #d86e6d;
    align-items: center;
}

.news-line{
    margin-left:10px;
    font-size: 12px;
    background-color: #ebf5fa;
    font-weight: bold;
      overflow: hidden;
}

.news-line-text{
    display: inline-block;
    color: #4f8aaf;
    animation: news-line-text 15s linear infinite;
}

/* Navigation bar */
.nav-pill{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #2a6877;
    border-top-left-radius: 50em;
    border-bottom-left-radius: 50em;
    background-color: #2a6877;
    height: 70px;
    width: 28%;
    padding-right: 45px;
    padding-left: 30px;
    color:#c5d7e3;
    align-items: center;
}

.nav-item {
    color: #c5d7e3;
    padding-bottom: 5px;
    padding: 0px;
    border-bottom: 2px solid #2a6877;
}

.nav-item:hover{
    color: #c5d7e3;
    border-bottom: 2px solid;
}

.nav-item-active{
    color: #c5d7e3;
    /* font-weight: bold; */
    border-bottom: 2px solid;
}

.nav-item:focus{
    color: #c5d7e3;
    /* font-weight: bold; */
    border-bottom: 2px solid;
}

.nav-item-support {
    color: #c5d7e3;
    padding-bottom: 5px;
    padding: 0px;
    border-bottom: 2px solid #2a6877;
}

.nav-item-support:hover{
    color: #c5d7e3;
    border-bottom: 2px solid;
}

.nav-item-support:focus{
    color: #c5d7e3;
    /* font-weight: bold; */
}

.table-heading{
    font-weight:500;
    color: #2a6877;
}

.mobile-nav-item{
    color: #5f5f5fb2 !important;
    margin-bottom: 15px;
    border-bottom: none !important;
}

.mobile-nav-item:focus{
    color: #00974a !important;
}

.mobile-nav-item-active{
    color: #00974a !important;
}

.mobile-support:focus{
    color: #5f5f5fb2 !important;
}

/* TABLE STYLES */
.table-header{
    width: 100%;
    background-color: #d5e1e7;
    display:flex;
    flex-direction: row;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #4f8aaf;
    margin-bottom: 10px;
}

thead th {
    font-weight: 500 !important;
}

.table-row-default{
    width: 100%;
    background-color: #f2f6f8;
    display:flex;
    flex-direction: row;
    padding: 7px;
    font-size: 13px;
    font-weight: 500;
}

.table-row-blue{
    width: 100%;
    background-color: #cbebe6;
    display:flex;
    flex-direction: row;
    padding: 7px;
    font-size: 13px;
    font-weight: 500;
}

.table-head-font{
    font-size: 15px;
}

.table-body-font{
    font-size: 13px;
}

.head-align{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
}

.head-align p{
    font-size: 13px;
    height: 100%;
}

.cl-1{
    padding-left: 10px;
    width: 27%;
}

.cl-2{
    width: 12%;
    text-align: right;
}

.cl-3{
    width: 15%;
    text-align: right;
}

.cl-4{
    width: 15%;
    text-align: right;
}

.cl-5{
    padding-right: 10px;
    width: 22%;
    display: flex;
    flex-direction: row;
}

.curve-top{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.curve-bottom{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.nickname{
    font-size:10px;
    text-decoration: underline;
    color: rgba(0, 0, 255, 0.557);
    cursor: pointer;
}

.nickname:hover{
    color: blue;
}

.price-note{
    font-size: 9px;
}

/* BUTTON TRAY */
.btn-blue{
    background-color: #2a6877;
    font-size: 12px;
    border-radius: 5px;
    border-color: #2a6877;
}

.btn-blue:focus,.btn-blue:hover {
    background-color: #1b3a42;
    font-size: 12px;
    border-radius: 5px;
    border-color: #1b3a42;
}

.btn-red{
    background-color: #de5353;
    font-size: 12px;
    border-radius: 5px;
    border-color: #de5353;
}

.btn-green{
    background-color: #0a8d4b;
    font-size: 12px;
    border-radius: 5px;
    border-color: #0a8d4b;
}

.btn-green:active{
    background-color: #056e39; 
 }
 
.btn-green:focus{
     background-color: #056e39; 
}
 
.btn-green:hover{
     background-color: #056e39; 
}

.btn-green:disabled{
    background-color: #02311a; 
    border-color: #02311a;
    cursor:no-drop;
}

.btn-default{
    background-color: #f7f7f793;
    font-size: 12px;
    border-radius: 5px;
    color: #5f5f5fb2;
    border-color: #f7f7f793;
}

.btn-default:active{
    background-color: #f7f7f793; 
    color: #5f5f5fb2; 
    border-color: #f7f7f793;
    box-shadow:  #f7f7f793;
 }
 
 .btn-default:focus{
     background-color: #f7f7f793;
     color: #5f5f5fb2; 
     border-color: #f7f7f793;
     box-shadow:  #f7f7f793;
  }
 
 .btn-default:hover{
     background-color: #f7f7f793; 
     color: #5f5f5fb2; 
     border-color: #f7f7f793;
     box-shadow:  #f7f7f793;
  }

.buy-btn{
    height: 25px;
    width: 50%;
    background-color: #2a6877;
    font-size: 11px;
    border-color: #2a6877;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
    border-radius: 5px;
    text-align: center; 
    margin-left: 15px;
    margin-right: 10px;
}

.buy-btn:active{
   background-color: #183136; 
}

.buy-btn:focus{
    background-color: #183136; 
 }

.buy-btn:hover{
    background-color: #183136; 
 }

.sell-btn{
    height: 25px;
    width: 50%;
    background-color: #de5353;
    font-size: 11px;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
    border-radius: 5px;
    text-align: center; 
}

.sell-btn-overlay{
    position: relative;
    height: 25px;
    width: 50%;
    margin: 0px;
    padding-left: 8px;
    border-radius: 5px;
    /* text-align: center;  */
    background-color: #de5353;
}

.btn-overlay {
    display: 'flex';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    opacity: 1;
    width: 100%;
    padding-top: 4px;
    transition: .3s ease;
    border-radius: 5px;
    text-align: center; 
    align-items: center;
    background-color: #b7aeaee1;
    
}

.sell-btn-overlay:hover .btn-overlay {
    opacity: 1;
}

@keyframes news-line-text {
    0% {
      transform: translate3d(200%, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: #48ff00;
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    /* z-index: -1; */
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    /* z-index: -1; */
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.btn-circle {
    width: 70px;
    height: 70px;
    text-align: center;
    padding: 0; 
    font-size: 12px;
    border: 0.3px solid;
    border-radius: 50%;
    background-color: transparent;
    font-size: 25px;
    border-color: #3d7683;
    color: #3d7683;
  }

  .btn-circle:focus,
  .btn-circle:active{
      box-shadow:none !important;
      outline:0px !important;
  }

  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 0; 
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
  }

  .btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 0; 
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
  }

  .tray{
    display: flex;
    flex-direction: column;
    justify-content:space-between; 
  }

  .tray-item{
      padding-left: 20px;
  }

  .tray-item p{
    font-size: 10px;
  }

  .button-tray{
      display: flex;
      flex-direction: row;
      justify-content:end;
      margin-top:30px;
      width: 100%;
  }

  .icon-vib{
    animation: shake 0.7s cubic-bezier(.36,.07,.19,.97) both infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  /* SUGGEST INVEST TABLE */
  .suggest-invest{
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
  }

  .invest-tray{
    padding: 15px;
    background-color: #d4e6f0;
    width: 95%;
    color: #4f8aaf;
  }


  /* buttons glowing */
  .buy-glow:hover{
    border-color: #0bf403;
    color: #3d7683;
    background-color: rgba(238,244,247,255);
    box-shadow: 0 0 0.1px #0bf403, 0 0 1px #0bf403, 0 0 2px #0bf403,
    0 0 5px #0bf403;
    /* -webkit-box-reflect: below 0.2px linear-gradient(transparent, #0005); */
  }

  .sell-glow:hover{
    border-color: #e94848;
    color: #3d7683;
    background-color: rgba(238,244,247,255);
    box-shadow: 0 0 0.1px #e94848, 0 0 1px #e94848, 0 0 2px #e94848,
    0 0 5px #e94848;
    /* -webkit-box-reflect: below 0.2px linear-gradient(transparent, #0005); */
  }

  .transfer-glow:hover{
    border-color: #3b3ee0;
    color: #3d7683;
    background-color: rgba(238,244,247,255);
    box-shadow: 0 0 0.1px #3b3ee0, 0 0 1px #3b3ee0, 0 0 2px #3b3ee0,
    0 0 5px #3b3ee0;
    /* -webkit-box-reflect: below 0.2px linear-gradient(transparent, #0005); */
  }

  .switch-glow:hover{
    border-color: #ffbb33;
    color: #3d7683;
    background-color: rgba(238,244,247,255);
    box-shadow: 0 0 0.1px #ffbb33, 0 0 1px #ffbb33, 0 0 2px #ffbb33,
    0 0 5px #ffbb33;
    /* -webkit-box-reflect: below 0.2px linear-gradient(transparent, #0005); */
  }

  .lock-glow:hover{
    border-color: #4a4a4e;
    color: #3d7683;
    background-color: rgba(238,244,247,255);
    box-shadow: 0 0 0.1px #4a4a4e, 0 0 1px #4a4a4e, 0 0 2px #4a4a4e,
    0 0 5px #4a4a4e;
    /* -webkit-box-reflect: below 0.2px linear-gradient(transparent, #0005); */
  }

  /* INVEST TABLE */
  .invest-table{
      margin-top:15px;
  }

  .it-head{
    display: flex;
    flex-direction: row;
    color: #4f8aaf;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .it-col-1{
      width: 50%;
  }

  .it-col-2{
    width: 25%;
  }

  .it-col-3{
    width: 25%;
    text-align: center;
  }

  .it-row{
      display: flex;
      flex-direction: row;
      color: #5f5f5fb2;
      margin-top:6px;
      font-size: 13px;
      font-weight: 500;
  }

  /* Banner */

  .banner{
      margin-top:0px;
      border-right: 1px solid #8b83832b;
  }

  .banner-color{
      color: #34b6af;
  }

  .banner-precentage{
      font-size: 110px;
      margin: 0px;
      padding: 0px;
      font-weight: 400;
  }

  .banner-precentage-small{
    font-size: 48px;
    margin: 0px;
    padding: 0px;
    font-weight: 400;
   }

  .banner-date{
      font-size: 10px;
  }

  .banner-date-small{
    font-size: 8px;
  }

  .banner-quote{
      font-size: 35px;
      padding-left: 20px;
  }

  .banner-quote-small{
    font-size: 20px;
    padding-left: 20px;
  }

  .text-small{
      font-size: 13px;
  }

  /* FOOTER */
  .footer{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }

  .footer-left{
      text-align: left;
  }

  .footer-right{
    text-align: right;
  }

  /* CHARTS */

  .multi-carousel-container{
      height: 100%;
      margin:0px;
  }

  .dougnet{
      display: flex;
      flex-direction: row;
      justify-content: end;
  }

  /* STEPPER */

.steps{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    /* padding-bottom: 20px; */
    width:30%;
}

/* Modal */
.modal-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* padding-top: 1rem; */
}

/* Payslip */
.payslip-upload{
    border: 1px solid rgba(0, 0, 0, 0.479);
    padding: 15px;
}

/* Image Login */
.panel-head .animated_tab{
    float:left;
    width:100%;
    text-align:right;
    text-transform : uppercase;
    /* margin-bottom:50px;	 */
    font-size: 10px;
    left: 10px;
    cursor: pointer;
}


.react-tel-input .form-control{
    width: 100% !important;
} 


.animated_tab-custom{
    position: relative;
    background: #fff;
    padding: 10px;
    margin-left: 0px;
    right: 0px;
    padding-right: 0px;
    border-radius: 25px 0px 0px 25px;
    width: 300px !important;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.075);
}


.panel-head .animated_tab li{
    position:relative;
    display:inline-block;	
}

.panel-head .animated_tab li a{
    display:flex;
    color:#999999;
    padding:10px 15px;
    font-weight:bold;
    text-decoration: none ;
}

.panel-head .animated_tab li.active a,
.panel-head .animated_tab li:hover a{
    color : #333333;
}

.panel-head .animated_tab li.active:before,
.panel-head .animated_tab li:hover:before,
.panel-head .animated_tab li.active:after,
.panel-head .animated_tab li:hover:after{
    background-color: #2d9957;
    position: absolute;
    width:55px;	
    height:2px;
}

.panel-head .animated_tab li:before{
    content: "";
    transition: all 0.5s ease-in-out;
    top: 0px;
    right: 0px;
    width: 0px;
    
}

.panel-head .animated_tab li:after {
    content: "";
    transition: all 0.5s ease-in-out;
    left: 0px;
    bottom: 0px;
    width: 0px;
    
}

.login-icon{
    padding-right: 5px;
}

.tab-item{
    align-items: center;
}

.right-border{
    border-right: 1px solid;
}

/* OTP */
.otp-body{
  text-align: center;
  padding: 5px;
 
}
.resend-button{
    margin-top:20px;
}

.input-group-width{
    width: 10%;
}

/* Overlay class */
.overlay {
    position: relative;
    color: #fff;
    height: 350px;
    padding: 30px;
    align-items: center;
    text-align: center;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  }

  .overlay-selection{
      display: flex;
      flex-direction: row;
      justify-content: center;
  }

  .overlay-link{
      cursor: pointer;
      border: 1px solid #5f5f5fb2;
      padding: 20px;
      border-radius: 5px;
  }

  .overlay-link:hover, .overlay-link:focus{
      /* text-decoration: underline; */
      background: lightcyan;
  }

  .overlay-quo{
      display: flex;
      flex-direction: row;
      justify-content: center;
  }

  .panel-head{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      margin: 0px;
  }

  .table-style{
    padding: 10px;
    font-size: 15px;
  }

  .table-style td, .table-style th {
    padding: 8px;
  }

  .table-style thead tr{
      background: #d5e1e7;
  }

  .table-style tbody tr:nth-child(even) {
      background-color: #f5f5f5;
  }

  .table-style tbody tr:nth-child(odd) {
    background-color: #cbebe6;
  }

  /* Pending table Style */
  .pending-table-style{
    padding: 10px;
    font-size: 15px;
  }

  
  .pending-table-style td, .pending-table-style th {
    padding: 8px;
  }

  .pending-table-style thead tr{
      background: #d5e1e7;
  }

  .pending-table-style tbody tr:nth-child(even) {
      background-color: #fff5f512;
  }

  .pending-table-style tbody tr:nth-child(odd) {
    background-color: #d4e6f0;
  }

  .fund-details{
      display: flex;
      flex-direction: column;
  }

  .pending-fund{
      font-size: 13px;
      font-weight: 500;
  }
  
  .pending-account{
    font-size: 12px;
  }

  .pending-type{
    font-size: 11px;
    font-weight: bold;
  }

  /* Table Extra Small button */

  .table-xsm-btn{
      display: flex;
      flex-direction: row;
      justify-content:center;
  }

  .table-xsm-btn button{
      margin: 5px;
      height: 25px;
      font-size: 8px;
      width: 25px;
      padding: 0;
  }

  .uploads-content{
      margin-top: 20px;
      min-height: 250px;
      position: relative;
      padding: 20px;
      border: 1px solid rgba(82, 80, 80, 0.121);
      height: 100%;
  }

  .account-number{
      font-size: 10px;
  }


/* TRANSACTION PAGE */
.transaction-container{
    /* padding: 30px; */
}

.transaction-form{
    margin-top:10px;
    min-height: 250px;
    background-color: #d5e3d54f;
    padding: 20px;
    border-radius: 5px;
}

.space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.statement-button{
    background-color: #2a6877;
    font-size: 12px;
    border-radius: 5px;
    border-color: #2a6877;
    width: 150px;
}

.statement-button:active{
    background-color: #183136; 
 }
 
 .statement-button:focus{
     background-color: #183136; 
  }
 
 .statement-button:hover{
     background-color: #183136; 
}

.search-button{
    background-color: #0a8d4b;
    font-size: 12px;
    border-radius: 5px;
    border-color: #0a8d4b;
    width: 150px;
}

.search-button:active{
    background-color: #0a8d4b; 
 }
 
 .search-button:focus{
     background-color: #0a8d4b; 
  }
 
 .search-button:hover{
     background-color: #5cb85c; 
}

/* TRANSACTION PAGE */
.trans-form{
    background-color: #cce7f1;
    height: 100%;
    width: 100%;
    border:1px solid;
    border-radius: 5px;
    padding: 20px;
}

.statement-container{
    display:flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
}

.statement-wrap{
    height: 50%;
    border: 1px solid #2a6877;
    border-radius: 5px;
    align-items: center;
    margin:0px;
}

/* .react-multi-carousel-item{
    height: 144px !important;
    width: 144px !important;
}

.react-multi-carousel-item:active{
    height: 244px !important;
    width: 244px !important;
} */

.strength-bar{
    height: 8px;
    width: 89%;
    float: right;
}

.strength-bar p {
    font-size: 8px !important;
    margin: 0px !important;
}

.sm-chart{
    height: 150px;
    width:150px;
    padding: 8px;
    animation: zoomOut 1s ease-out;
}
.lg-chart{
    height: 250px !important;
    width:250px !important;
    padding: 8px !important;
}

.mobile-chart{
    display: none;
}

@media screen and (max-width: 767px) {
    .news{
        display: none;
    }

    .flip-mb{
        display: flex; flex-flow: column;
    }

    .tray{
         order: 1; 
    }
    .suggest-invest{
        display: none;
    }

    .mobile-chart{
        display:flex;
    }

    .portfolio{
        order: 2; 
    }

    .banner-container{
        display: none;
    }

    .btn-circle{
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 0; 
        font-size: 8px;
        border: 0.3px solid;
        border-radius: 50%;
        background-color: transparent;
        font-size: 15px;
        border-color: #3d7683;
        color: #3d7683;
    }

    .nav-responce{
        display: flex;
        flex-direction: row;
        justify-content: end;
    }

    .nav-pill{
        font-size: 12px;
        width: 70%;
    }

    .strength-bar{
        height: 8px;
        width: 100%;
    }
    
    .strength-container{
        width: 100%;
    }

    .user-fund-detail{
        margin-top: 25px;
    }

    .mobile-signout{
        display: initial !important;
    }

    .mbrs{
        display: none;
    }
}

/* Loader Modal Content */
.ui-block .modal-content {
    border: none;
    background: transparent;
}

.ui-block .modal-content div {
    margin: auto;
    margin-top: 50%;
}

.note-download{
    width: 100%;
    border: 1px solid #5f5f5f1e;
    min-height: 50px;
    background-color: #f0ffff78;
    padding: 8px;
    font-weight: 600;
}

.download-all{
    color: #0d6efd !important;
}

.terms-condition{
    display: flex;
    flex-direction: row;
    font-size: 13px;
}

.conditions{
    cursor: pointer;
    text-decoration: underline;
    color: #5f5f5fb2;
}

.conditions:hover, .conditions:focus, .conditions:active{
    color: #5f5f5fb2 !important;
}

.popover-button{
    width: 20px;
    height: 20px;
    font-size: 12px;
    padding: 0px;
    font-weight: bold;
    box-shadow: 3px 6px 6px #00000020;
}

.popover-number{
    text-align: end;
}

/* Profile */
.profile-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid transparent;
    border-radius: .25rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
}
.me-2 {
    margin-right: .5rem!important;
}

.warning-icon{
   flex-direction: row;
   justify-self: center;
}

.label-button{
    border: 1px solid #54535370;
}

.label-button:focus{
    box-shadow: none !important;
}

.row-dot {
    width: 8px;
    height: 8px;
    margin-right: 10px;
    display: inline-block;
    border-radius: 100%;
}

.dot-column{
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.fund-name-row{
    margin-left: 15px;
}

._loading_overlay_overlay{
    z-index: 5000 !important;
    background-color: rgba(0, 0, 0, 0.286);
    min-height: 615px !important;
}

@keyframes zoomOut {
    0% {
        transform: scale(1.5,1.5);
    }
    50% {
        transform: scale(1,1);
    }
    100% {
        transform: scale(1,1);
    }
}

/* Media Queries */
@media screen and (max-width: 1400px) {
    .h3, h3{
        font-size: 1.5rem;
    } 

    .card-text{
        font-size: 11px;
        font-weight: 500;
    }

    .login-card{
        min-height:450px
    }

    .h6, h6 {
        font-size: 0.8rem;
    }

    .h4, h4 {
        font-size: 1.3rem;
    }

    .nav-pill{
        font-size: 12px;
    }

    .btn-circle{
        width: 65px !important;
        height: 65px !important;
        font-size: 22px;
    }

    .table-header{
        font-size: 12px;
    }

    .table-row-blue{
        font-size: 11px;
        cursor: pointer;
    }
    .table-row-default{
        font-size: 11px;
        cursor: pointer;
    }

    .table-head-font{
        font-size: 12px;
    }

    .table-body-font{
        font-size: 11px;
    }

    .it-head{
        font-size: 12px;
    }

    .it-row{
        font-size: 11px;
    }

    .sm-chart{
        height: 130px;
        width:130px;
        padding: 8px;
        animation:zoomOut 1s ease,
    }
    .lg-chart{
        height: 200px !important;
        width:200px !important;
        padding: 8px !important;
    }

    .default-chart{
        height: 130px ;
        width:130px ;
        padding: 8px;
    }

    .sm-chart p{
        font-size: 10px !important;
    }

}

/* Media Queries */
@media screen and (max-width: 1198px) {
    .h3, h3{
        font-size: 1.3rem;
    } 

    .card-text{
        font-size: 9px;
        font-weight: 500;
    }

    .login-card{
        min-height:400px
    }

    .h6, h6 {
        font-size: 0.7rem;
    }

    .h4, h4 {
        font-size: 1.1rem;
    }

    .nav-pill{
        font-size: 11px;
    }

    .table-header{
        font-size: 11px;
    }

    .table-row-blue{
        font-size: 10px;
    }
    .table-row-default{
        font-size: 10px;
    }

    .table-head-font{
        font-size: 11px;
    }

    .table-body-font{
        font-size: 10px;
    }

    .it-head{
        font-size: 11px;
    }

    .it-row{
        font-size: 10px;
    }

    .sm-chart{
        height: 100px;
        width:100px;
        padding: 8px;
    }
    .lg-chart{
        height: 150px !important;
        width:150px !important;
        padding: 8px !important;
    }

    .btn-circle{
        width:40px;
        height:40px;
        font-size: 18px;
    }

    .tray-item{
        padding-left: 10px;
    }

    .banner h5{
        font-size: 18px;
    }

    .banner p{
        font-size: 14px;
    }

    .banner-quote{
        font-size: 25px;
    }
}

/* Media Queries */
@media screen and (max-width: 991px) {
    .news{
        display: none;
    }

    .mini-fund-counter{
        display: flex;
        flex-direction: column;
        vertical-align: center;
    }

    .mobile-counter{
        font-size: 12px;
    }

    .flip-mb{
        display: flex; flex-flow: column;
    }

    .tray{
         order: 1; 
    }
    .suggest-invest{
        display: none;
    }

    .portfolio{
        order: 2; 
        width: 100%;
    }

    .horiz-scroll{
        overflow-x: auto;
    }

    .mobile-chart{
        display:flex;
    }

    .banner-container{
        display: none;
    }

    .dougnet{
        justify-content: center;
    }

    .btn-circle{
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 0; 
        font-size: 8px;
        border: 0.3px solid;
        border-radius: 50%;
        background-color: transparent;
        font-size: 15px;
        border-color: #3d7683;
        color: #3d7683;
    }

    .nav-responce{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .nav-pill{
        font-size: 12px;
        width: 70%;
    }

    .nav-web{
        display: none;
    }

    .nav-mobile{
        display: flex !important;
        width: 15% !important;
    }

    .strength-bar{
        height: 8px;
        width: 100%;
    }
    
    .strength-container{
        width: 100%;
    }

    .portfolio, .tray, .dougnet{
        width: 100%;
    }

    .mobile-signout{
        display: initial !important;
    }

    .table-header,
    .table-row-default,
    .table-row-blue {
        min-width: 700px;
    }

    .signout-link {
        margin-top: auto; 
    }

    .offcanvas-body-custom {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .statement-wrap{
        height: 100% !important;
    }

    .statement-container{
        padding-top: 20px;
    }

    .trans-form{
        background: none;
    }

    .mobile-t-headding{
        padding-bottom: 15px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .statement-container{
        padding-top: 0px;
    }

    .statement-wrap{
        height: 50% !important;
    }

    .statement-wrap h2 {
        font-size: 18px;
    }

    .statement-container button{
        width: 90px !important;
        font-size: 18px !important;
    }

    .transaction-table{
        overflow-x: auto;
    }
}

/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (max-width:481px)  {
    .buy-btn {
        font-size: 10px !important;
    }

    .sell-btn{
        font-size: 10px !important;
    }

    
    .btn-circle{
        width: 55px !important;
        height: 55px !important;
    }
}

/* smartphones, iPhone, portrait 480x320 phones */ 
@media (max-width:320px)  {
    .buy-btn {
        font-size: 8px !important;
    }

    .sell-btn{
        font-size: 8px !important;
    }

    .btn-circle{
        width: 45px !important;
        height: 45px !important;
    }
}


/* Report Transaction */

.report{
    color: rgba(0, 0, 0, 0.57);
    height: 1024px;

}

.report-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.close-x {
    float:right;
    display:inline-block;
    padding:2px 5px;
    cursor: pointer;
}

.doc-container{
    margin-top: 10px;
    text-align: center;
    overflow-x: hidden;
}

.pending-date{
    font-weight: 600 !important;
}

.disclaimer{
    font-size: 10px;
    margin-top: 0px;
    padding-top: 0px;
}

.foot-pdf{
    bottom: 0px;
}

footer {
    position: relative;
    clear:both;
} 


.footer-styles{
    color: #5f5f5fb2;
    background-color: rgba(238,244,247,255);
    padding-top: 30px;
}

.ul-change {
    list-style: none;
}

.ul-change li::before{
    content: "\2022";
    color: red;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}

.otp-confirm{
    width: 30vw;
    max-width: 30vw; 
}

.overlay-div {
    background-color: rgba(0, 0, 0, 0.5);    
    z-index: inherit;
}

.phone-register-mobile .form-control{
    height: 30px;
}

.stepper-clickable{
    cursor: pointer;
}

.stepper-clickable:hover{
    text-decoration: underline;
}

.required-field::before {
    content: "*";
    color: rgba(255, 0, 0, 0.537);
}

.complete_list_item.active{
    background-color: #0d6efd21 !important;
    border-color: #0d6efd21 !important;
}

